import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'

import styles from '../styles/contact.module.css'

export default function Referral({ data }) {
  return (
    <Layout title="Referral">
      <h1>{data.markdownRemark.frontmatter.header}</h1>
      <div className="center-text">
        {data.markdownRemark.frontmatter.description ? <ReactMarkdown source={data.markdownRemark.frontmatter.description} /> : null}
      </div>
      <div className={styles.contact_form}>
        <form name="referral" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
          <input type="hidden" name="form-name" value="referral" />
          <fieldset>
            <legend>Referral Source</legend>
            <label htmlFor="referrerName">Name</label>
            <input type="text" id="referrerName" name="referrerName" required />
            <label htmlFor="company">Firm/Company Name</label>
            <input type="text" id="company" name="company" required />
            <label htmlFor="referrerAddress1">Street Address Line 1</label>
            <input type="text" id="referrerAddress1" name="referrerAddress1" required />
            <label htmlFor="referrerAddress2">Street Address Line 2</label>
            <input type="text" id="referrerAddress2" name="referrerAddress2" />
            <div className={styles.city_state}>
              <div>
                <label htmlFor="referrerCity">City</label>
                <input type="text" id="referrerCity" name="referrerCity" required />
              </div>
              <div>
                <label htmlFor="referrerState">State</label>
                <input type="text" id="referrerState" name="referrerState" required />
              </div>
              <div>
                <label htmlFor="referrerZip">Zip Code</label>
                <input type="text" id="referrerZip" name="referrerZip" required />
              </div>
            </div>
            <label htmlFor="referrerEmail">Email</label>
            <input type="email" id="referrerEmail" name="referrerEmail" required />
            <label htmlFor="referrerPhone">Phone</label>
            <input type="tel" id="referrerPhone" name="referrerPhone" required />
            <label htmlFor="caseType">Case Type</label>
            <label htmlFor="plainDef" className="visually-hidden">Pre-suit, Litigated, Vocational - Litigated, or Vocational - Non-litigated?</label>
            <div className="select">
              <select id="plainDef" name="plainDef" required>
                <option name="">Select One</option>
                <option name="plaintiff">Plaintiff</option>
                <option name="defense">Defense</option>
              </select>
              <span class="focus"></span>
            </div>
            <label htmlFor="preLit" className="visually-hidden">Pre-suit, Litigated, Vocational - Litigated, or Vocational - Non-litigated?</label>
            <div className="select">
              <select id="preLit" name="preLit" required>
                <option name="">Select One</option>
                <option name="presuit">Pre-suit</option>
                <option name="litigated">Litigated</option>
                <option name="vocLitigated">Vocational - Litigated</option>
                <option name="vocNonLitigated">Vocational - Non-Litigated</option>
              </select>
              <span class="focus"></span>
            </div>
            <label htmlFor="sued">If litigated, please indicate who is being sued (for company conflict check).</label>
            <input type="text" name="sued" id="sued" required />
            <label htmlFor="service">Service Requested</label>
            <div className="select">
              <select id="service" name="service" required>
                <option name="">Select One</option>
                <option name="lifeCarePlan">Life Care Plan</option>
                <option name="medCostProjection">Medical Cost Projection</option>
                <option name="vocational">Vocational</option>
              </select>
              <span class="focus"></span>
            </div>
            <label htmlFor="injuryDate">Date of Injury</label>
            <input type="date" name="injuryDate" id="injuryDate" required />
          </fieldset>
          <fieldset>
            <legend>Case Information</legend>
            <label htmlFor="caseName">Name</label>
            <input type="text" id="caseName" name="caseName" required />
            <label htmlFor="dob">Date of Birth</label>
            <input type="date" id="dob" name="dob" required />
            <fieldset>
              <legend>Gender</legend>
              <label>
                <input
                  type="radio"
                  value="female"
                  name="gender"
                /> Female
              </label>
              <label>
                <input
                  type="radio"
                  value="male"
                  name="gender"
                /> Male
              </label>
            </fieldset>
            <label htmlFor="casePhone">Phone</label>
            <input type="tel" id="casePhone" name="casePhone" required />
            <label htmlFor="caseAddress1">Street Address Line 1</label>
            <input type="text" id="caseAddress1" name="caseAddress1" required />
            <label htmlFor="caseAddress2">Street Address Line 2</label>
            <input type="text" id="caseAddress2" name="caseAddress2" />
            <div className={styles.city_state}>
              <div>
                <label htmlFor="caseCity">City</label>
                <input type="text" id="caseCity" name="caseCity" required />
              </div>
              <div>
                <label htmlFor="caseState">State</label>
                <input type="text" id="caseState" name="caseState" required />
              </div>
              <div>
                <label htmlFor="caseZip">Zip Code</label>
                <input type="text" id="caseZip" name="caseZip" required />
              </div>
            </div>
            <label htmlFor="diagnosis">Injury Related Diagnosis</label>
            <textarea id="diagnosis" name="diagnosis" required></textarea>
            <label htmlFor="medInfo">Pertinent Medical Information</label>
            <textarea id="medInfo" name="medInfo"></textarea>
          </fieldset>
          <div className="flex-row">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query ReferralQuery {
  markdownRemark(frontmatter: {title: {eq: "Referral"}}) {
    frontmatter {
      header
      description
    }
  }
}
`